.notification-content {
    display: flex;
    padding-top: 16px;

    & > .anticon {
        font-size: 60px;
        color: #1890ff;
        margin-right: 1rem;
    }

    & > div {
        flex: 1;

        h3 {
            margin-bottom: 0.5em;
        }
    }
}
