@import "../_variables";

.logo {
    display: flex;
    padding: 12px 7px;
    overflow: hidden;
    // NOTE: to account for logo padding
    max-width: 286px;

    svg {
        height: 24px;
        flex-shrink: 0;
    }

    & > div {
        padding-right: 2px;
        margin-right: 8px;
        border-right: 1px solid rgba(255, 255, 255, 0.15);
        display: flex;
        flex-direction: column;
    }

    .st0 {
        enable-background: new;
    }

    .st1 {
        fill: $white;

        &.red {
            fill: $red;
        }
    }

    .app-name {
        color: $white;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 1.9rem;
        text-transform: capitalize;
        font-weight: 600;
        line-height: 1;
    }

    .st0,
    .env,
    .app-name {
        transition: opacity 250ms, transform 250ms;
    }

    .hide {
        opacity: 0;
        transform: translateX(-25%);
    }

    .env {
        color: $white;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 1.3rem;
        text-transform: capitalize;
        font-style: italic;
        line-height: 1;
        margin-left: 35px;
    }
}
