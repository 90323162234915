.unauthenticated {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2rem;

  #RE_Logo {
    width: 50%;
    max-width: 48rem;

    .heart {
      fill: #c6002b;
    }

    .radio {
      fill: #000;
    }

    .edit {
      fill: #464646;
    }
  }

  hr {
    width: 75%;
    max-width: 144rem;
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
    margin-top: 2rem;
  }

  h3 {
    font-size: 4rem;
    margin-top: 4rem;
  }
}
