.app-header {
    background: #fff;
    height: 64px;
    padding-left: 50px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 64px;

    h3 {
        margin: 0;
    }
}
