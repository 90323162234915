.help-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    h3 {
        font-weight: 700;
    }

    ul {
        padding: 0 2rem;
        margin: 0;
    }

    .anticon {
        margin-right: 1ch;
    }
}
