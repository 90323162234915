$white: #fff;
$red: #c6002b;
$light-grey: #e9e9e9;
$sidebar-session-bg: #002140;

$grid-breakpoints: (
    xs5: 0,
    xs4: 359px,
    xs3: 399px,
    xs2: 479px,
    xs1: 599px,
    sm4: 719px,
    sm3: 839px,
    sm2: 959px,
    sm1: 1023px,
    md2: 1279px,
    md1: 1439px,
    lg2: 1599px,
    lg1: 1919px,
    xl1: 1920px,
);
