@import "../_variables";
.apps-list-container {
    flex-grow: 1;

    .apps-list {
        margin: 0 1.6rem;
        display: grid;
        grid-column-gap: 1.6rem;
        grid-row-gap: 1.6rem;
        grid-template-columns: repeat(4, 1fr);

        @media (min-width: map-get($grid-breakpoints, sm4)) {
            grid-template-columns: repeat(8, 1fr);
        }

        @media (min-width: map-get($grid-breakpoints, sm3)) {
            grid-column-gap: 2.4rem;
        }

        @media (min-width: map-get($grid-breakpoints, sm2)) {
            grid-template-columns: repeat(12, 1fr);
        }

        .card-avatar {
            path {
                fill: #fff;
            }
        }

        .app-item {
            display: block;
            max-width: 100%;
            height: inherit;
            grid-column: auto / span 4;

            .app-card {
                height: 100%;
                width: 100%;

                .ant-card-body {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    height: inherit;

                    .ant-card-meta {
                        max-width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                    }

                    .card-avatar {
                        transition: background 300ms;
                        width: 6.4rem;
                        height: 6.4rem;
                        font-size: 3.2rem;
                        background: #ccc;
                        border-radius: 50%;
                        display: inline-flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        &:hover {
                            background: #1890ff;
                        }
                    }
                }

                &:hover .card-avatar {
                    background: #1890ff;
                }
            }
        }

        &.empty {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }
}
