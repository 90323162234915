@import "_variables";

html {
    font-size: 10px;
}

.app {
    display: flex;
    height: 100vh;

    .clickable {
        cursor: pointer;
    }

    .sidenav {
        overflow: auto;
        display: flex;
        flex-direction: column;
        height: 100vh;

        .menu {
            flex: 1;
            justify-content: space-between;
            display: flex;
            flex-direction: column;
        }
    }

    .app-content {
        display: flex;
        flex-direction: column;
        transition: width 0.2s;
        width: calc(100vw - 30rem);

        .apps-view {
            display: flex;
            flex-direction: column;
            padding: 0 0 10px 0;
            overflow-y: scroll;
            min-height: calc(100% - 64px);
            display: flex;
            flex-direction: column;
            position: relative;

            .filters {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                margin: 1rem 1.6rem;

                .search {
                    transition: width 0.3s ease-in-out;
                    width: 30rem;

                    &:focus,
                    &:hover,
                    &:active,
                    &:focus-within {
                        width: 40rem;
                    }
                }
            }
        }
    }

    .app-footer {
        background-color: $light-grey;
        .app-name {
            text-transform: capitalize;
        }
    }

    &:not(.ant-layout-sider-collapsed) > .ant-layout-sider-children > .sidenav {
        min-width: 30rem;
    }

    .bottom-nav {
        .ant-menu-inline,
        .ant-menu-inline-collapsed {
            background-color: $sidebar-session-bg;
        }
        .menu-item {
            padding-left: 2.4rem;
        }
    }

    .ant-layout-sider-collapsed {
        .logo {
            width: 4.8rem;
        }

        & + .content {
            width: calc(100vw - 4.8rem);
        }

        .ant-menu-inline-collapsed {
            width: 4.8rem;

            & > .ant-menu-item,
            & > .ant-menu-submenu > .ant-menu-submenu-title {
                padding: 0 1.6rem !important;
            }

            & > .ant-menu-submenu > .ant-menu-submenu-title {
                margin-top: 0.8rem;
            }
        }
    }

    .ant-menu-inline .ant-menu-item,
    .ant-menu-inline .ant-menu-submenu-title {
        &,
        .anticon {
            font-size: 1.6rem;
        }
    }
}
